export default [
    {
        id_pendaftaran:'62e8ea47839bd46309d2a33c',
        nama_industri:'PT Hyundai Inti Development',
        nama_penanggung_jawab:'Bae Jae Yong (Mr)',
        dibuat_pada:'02/08/2022 16:11',
        diubah_pada:'23/05/2023 09:45',
        nomor_uid:'',
        validasi:'Menunggu Validasi',
        sensor: {
            "ph": [
                {
                    "brand": "Endress+Hauser - CPF81D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "14",
                    "measurementMethod": "Glass Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan 2 nilai Buffer Solution yaitu pH 7.00 dan pH 4.00"
                }
            ],
            "cod": [
                {
                    "brand": "Endress+Hauser - CAS51D",
                    "probe": "Single Probe",
                    "maxMeasurement": "10",
                    "minMeasurement": "1500",
                    "measurementMethod": "UV Photometric",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "tss": [
                {
                    "brand": "Endress+Hauser - CUS52D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "2000",
                    "measurementMethod": "Four Beam Alternating Light",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan metode sample yang di ukur berdasarkan gravimetri (Internal LAB) atau Lab rujukan KLHK"
                }
            ],
            "nh3n": [
                {
                    "brand": "Endress+Hauser - CAS40D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "1000",
                    "measurementMethod": "Ion-Selectic Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "debit": [
                {
                    "brand": "Endress+Hauser - 5W4C2F",
                    "probe": "5W4C2F",
                    "maxMeasurement": "55",
                    "minMeasurement": "500",
                    "measurementMethod": "Electro Magnetic FM",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan menggunakan flowmeter yang sudah terkalibrasi/terakreditasi."
                }
            ]
        },
        logger: [
            {
                "brand": "Endress+Hauser",
                "type": "Memograph S / RSG45-AA1BBAAAC2B1+AAGBN1"
            }
        ],
        umum: {
            "companyName": "PT Hyundai Inti Development",
            "companyID": null,
            "companyType": null,
            "siupFile": {
                "path": "/storage/OSS NIB PT HID 17.06.22.pdf",
                "upload": 2,
                "progress": 100
            },
            "responsiblePerson": "Bae Jae Yong (Mr)",
            "companyAddress": "Gedung CIMB Niaga Lantai 3, Jl. M. H. Thamrin Lippo Cikarang, Desa/Kelurahan Cibatu, Kec. Cikarang Selatan",
            "companyProvince": "5deda806b267f9c9e60d2db6",
            "companyCity": "5dede4b4b267f9c9e60e2a28",
            "companyPhone": "(021) 8972378",
            "companyMail": "zainur@hyundai-inti.com",
            "responsibleTestPerson": "Meidia Safitri",
            "responsibleTestPhone": "081213018626",
            "responsibleTestMail": "meidiasafitri@hyundai-inti.com",
            "createdAt": 1659431495,
            "compProvinceName": "Jawa Barat",
            "compCityName": "Kabupaten Bekasi"
        },
        validitas: [
            {
                "_id": "lfLI05BM6rchBdR3EsNk7rzq",
                "user_id": "Admin",
                "keterangan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Ditolak, belum melengkapi dokumen</p>",
                "status": "rejected",
                "tanggal_uji": null,
                "created_time": 1684809941
            }
        ]
    },
    {
        id_pendaftaran:'631c4be762d22b328416cbc4',
        nama_industri:'PT MULIA PRIMA NUSA',
        nama_penanggung_jawab:'Ferdinand N Iskandar',
        dibuat_pada:'02/08/2022 16:11',
        diubah_pada:'16/10/2022 22:48',
        nomor_uid:'',
        validasi:'Menunggu Validasi',
        sensor: {
            "ph": [
                {
                    "brand": "Endress+Hauser - CPF81D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "14",
                    "measurementMethod": "Glass Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan 2 nilai Buffer Solution yaitu pH 7.00 dan pH 4.00"
                }
            ],
            "cod": [
                {
                    "brand": "Endress+Hauser - CAS51D",
                    "probe": "Single Probe",
                    "maxMeasurement": "10",
                    "minMeasurement": "1500",
                    "measurementMethod": "UV Photometric",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "tss": [
                {
                    "brand": "Endress+Hauser - CUS52D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "2000",
                    "measurementMethod": "Four Beam Alternating Light",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan metode sample yang di ukur berdasarkan gravimetri (Internal LAB) atau Lab rujukan KLHK"
                }
            ],
            "nh3n": [
                {
                    "brand": "Endress+Hauser - CAS40D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "1000",
                    "measurementMethod": "Ion-Selectic Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "debit": [
                {
                    "brand": "Endress+Hauser - 5W4C2F",
                    "probe": "5W4C2F",
                    "maxMeasurement": "55",
                    "minMeasurement": "500",
                    "measurementMethod": "Electro Magnetic FM",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan menggunakan flowmeter yang sudah terkalibrasi/terakreditasi."
                }
            ]
        },
        logger: [
            {
                "brand": "Endress+Hauser",
                "type": "Memograph S / RSG45-AA1BBAAAC2B1+AAGBN1"
            }
        ],
        umum: {
            "companyName": "PT MULIA PRIMA NUSA",
            "companyID": null,
            "companyType": null,
            "siupFile": {
                "path": "/storage/SIUP PT. MPN.pdf",
                "upload": 2,
                "progress": 100
            },
            "responsiblePerson": "Ferdinand N Iskandar",
            "companyAddress": "Gedung STC Senayan Lt. 4 Ruang 31-34, Jl. Asia Afrika Pintu IX, Kel. Gelora, Kec. Tanah Abang, Kota Adm. Jak Pusat",
            "companyProvince": "5deda7eeb267f9c9e60d2d57",
            "companyCity": "5dede3a3b267f9c9e60e2576",
            "companyPhone": "(021) 5710467",
            "companyMail": "pt.mpn2020@gmail.com",
            "responsibleTestPerson": "Yustin Meilina",
            "responsibleTestPhone": "087882270707",
            "responsibleTestMail": "justinmeilina@yahoo.com",
            "createdAt": 1662798823,
            "compProvinceName": "DKI Jakarta",
            "compCityName": "Kota Jakarta Pusat"
        },
        validitas: [
            {
                "_id": "lfLI05BM6rchBdR3EsNk7rzq",
                "user_id": "Admin",
                "keterangan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Ditolak, belum melengkapi dokumen</p>",
                "status": "rejected",
                "tanggal_uji": null,
                "created_time": 1684809941
            }
        ]
    },
    {
        id_pendaftaran:'6324029b110bec4f64a9df90',
        nama_industri:'PT. BUMIPALMA LESTARIPERSADA',
        nama_penanggung_jawab:'FRANCISCUS COSTAN',
        dibuat_pada:'16/09/2022 11:59',
        diubah_pada:'04/10/2022 12:32',
        nomor_uid:'2000020221023',
        validasi:'Diterima',
        sensor: {
            "ph": [
                {
                    "brand": "Endress+Hauser - CPF81D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "14",
                    "measurementMethod": "Glass Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan 2 nilai Buffer Solution yaitu pH 7.00 dan pH 4.00"
                }
            ],
            "cod": [
                {
                    "brand": "Endress+Hauser - CAS51D",
                    "probe": "Single Probe",
                    "maxMeasurement": "10",
                    "minMeasurement": "1500",
                    "measurementMethod": "UV Photometric",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "tss": [
                {
                    "brand": "Endress+Hauser - CUS52D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "2000",
                    "measurementMethod": "Four Beam Alternating Light",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan metode sample yang di ukur berdasarkan gravimetri (Internal LAB) atau Lab rujukan KLHK"
                }
            ],
            "nh3n": [
                {
                    "brand": "Endress+Hauser - CAS40D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "1000",
                    "measurementMethod": "Ion-Selectic Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "debit": [
                {
                    "brand": "Endress+Hauser - 5W4C2F",
                    "probe": "5W4C2F",
                    "maxMeasurement": "55",
                    "minMeasurement": "500",
                    "measurementMethod": "Electro Magnetic FM",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan menggunakan flowmeter yang sudah terkalibrasi/terakreditasi."
                }
            ]
        },
        logger: [
            {
                "brand": "Endress+Hauser",
                "type": "Memograph S / RSG45-AA1BBAAAC2B1+AAGBN1"
            }
        ],
        umum: {
            "companyName": "PT. BUMIPALMA LESTARIPERSADA",
            "companyID": null,
            "companyType": null,
            "siupFile": {
                "path": "/storage/SIUP.pdf",
                "upload": 2,
                "progress": 100
            },
            "responsiblePerson": "FRANCISCUS COSTAN",
            "companyAddress": "Kecamatan Tempuling Rejeh, Keritang, Enok",
            "companyProvince": "5deda754b267f9c9e60d2aa4",
            "companyCity": "5dedd3e9b267f9c9e60de4a9",
            "companyPhone": "021-3925777",
            "companyMail": "staff.ehsd.idse@sinarmas-agri.com",
            "responsibleTestPerson": "Rudi Ginting",
            "responsibleTestPhone": "085265875000",
            "responsibleTestMail": "mgr.bpmm@sinarmas-agri.com",
            "createdAt": 1663304347,
            "compProvinceName": "Riau",
            "compCityName": "Kabupaten Indragiri Hilir"
        },
        validitas: [
            {
                "_id": "lfLI05BM6rchBdR3EsNk7rzq",
                "user_id": "Admin",
                "keterangan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Ditolak, belum melengkapi dokumen</p>",
                "status": "rejected",
                "tanggal_uji": null,
                "created_time": 1684809941
            }
        ]
    },
    {
        id_pendaftaran:'632c123d110bec4f644fa488',
        nama_industri:'PT. EKA AKURASI ENVITAMA',
        nama_penanggung_jawab:'Fadli Purnomo',
        dibuat_pada:'13/10/2022 12:55',
        diubah_pada:'13/10/2022 12:55',
        nomor_uid:'',
        validasi:'Menunggu Validasi',
        sensor: {
            "ph": [
                {
                    "brand": "Endress+Hauser - CPF81D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "14",
                    "measurementMethod": "Glass Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan 2 nilai Buffer Solution yaitu pH 7.00 dan pH 4.00"
                }
            ],
            "cod": [
                {
                    "brand": "Endress+Hauser - CAS51D",
                    "probe": "Single Probe",
                    "maxMeasurement": "10",
                    "minMeasurement": "1500",
                    "measurementMethod": "UV Photometric",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "tss": [
                {
                    "brand": "Endress+Hauser - CUS52D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "2000",
                    "measurementMethod": "Four Beam Alternating Light",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan metode sample yang di ukur berdasarkan gravimetri (Internal LAB) atau Lab rujukan KLHK"
                }
            ],
            "nh3n": [
                {
                    "brand": "Endress+Hauser - CAS40D",
                    "probe": "Single Probe",
                    "maxMeasurement": "0",
                    "minMeasurement": "1000",
                    "measurementMethod": "Ion-Selectic Electrode",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan pengukuran menggunakan Standard solution KHP atau menggunakan data actual yang sudah divalidasi oleh Lab rujukan KLHK"
                }
            ],
            "debit": [
                {
                    "brand": "Endress+Hauser - 5W4C2F",
                    "probe": "5W4C2F",
                    "maxMeasurement": "55",
                    "minMeasurement": "500",
                    "measurementMethod": "Electro Magnetic FM",
                    "calibrationSchedule": "6 bulan sekali",
                    "calibrationMethod": "Perbandingan menggunakan flowmeter yang sudah terkalibrasi/terakreditasi."
                }
            ]
        },
        logger: [
            {
                "brand": "Endress+Hauser",
                "type": "Memograph S / RSG45-AA1BBAAAC2B1+AAGBN1"
            }
        ],
        umum: {
            "companyName": "PT. EKA AKURASI ENVITAMA",
            "companyID": null,
            "companyType": null,
            "siupFile": {
                "path": "/storage/NIB Eka Akurasi Envitama.pdf",
                "upload": 2,
                "progress": 100
            },
            "responsiblePerson": "Fadli Purnomo",
            "companyAddress": "Proklamasi Blok.A23, Jl. Raya Proklamasi Kel. Mekarjaya, Kec. Sukmajaya, Kota Depok 16411",
            "companyProvince": "5deda806b267f9c9e60d2db6",
            "companyCity": "5dede540b267f9c9e60e2c89",
            "companyPhone": "02177837672",
            "companyMail": "admin@Ekalab.co.id",
            "responsibleTestPerson": "Fadli Purnomo",
            "responsibleTestPhone": "082124695631",
            "responsibleTestMail": "fadlipurnomo87@gmail.com",
            "createdAt": 1663832637,
            "compProvinceName": "Jawa Barat",
            "compCityName": "Kota Depok"
        },
        validitas: [
            {
                "_id": "lfLI05BM6rchBdR3EsNk7rzq",
                "user_id": "Admin",
                "keterangan": "<p style=\"margin: auto; line-height:1.2; font-weight:400;\">Ditolak, belum melengkapi dokumen</p>",
                "status": "rejected",
                "tanggal_uji": null,
                "created_time": 1684809941
            }
        ]
    },
  ];
  



  